<template>
  <div class="financing-service">
    <div class="container">
      <div class="Header-item"><Header /></div>
      <div class=""><Carousel /></div>
        <!-- 上市前融资服务 -->
      <div class="main">
        <div class="title-box"><span class="financing-title">{{ $t("lang.develop.TITLE") }}</span></div>
        <!-- 企业发展的资本路劲模块 -->
        <div class="part">
          <img
            :src="developimgurl"
            alt=""
            class="img-web"
          />
          <img
            src="../assets/service_way_mob_img_1@3x.png"
            alt=""
            class="img-mob"
            @click="previewImg(require('../assets/service_way_mob_img_1@3x.png'))"
          />
        </div>
        <div class="title-box"><span class="financing-title">{{ $t("lang.approach.TITLE") }}</span></div>
        <!-- 最有效的境外上市途径 -->
        <div class="part">
          <div class="abroad-way">
            <img
            :src="servicewayurl"
            alt=""
            class="abroad-way-img"
          />
          <div class="step">
            <div class="step-box">
            <div class="step-img">
            <div class="steptext"><div class="steptext-item">{{ $t("lang.abroadbadyimg.stepone") }}</div></div>
            </div>
            <span>{{ $t("lang.abroadbadyimg.step1text") }}</span>
            </div>
            <div class="step-box">
            <div class="step-img step-img2">
            <div class="steptext"><div class="steptext-item">{{ $t("lang.abroadbadyimg.steptwo") }}</div></div>
            </div>
            <span>{{ $t("lang.abroadbadyimg.step2text") }}</span>
            </div>
            <div class="step-box">
            <div class="step-img step-img1">
            <div class="steptext"><div class="steptext-item">{{ $t("lang.abroadbadyimg.stepthree") }}</div></div>
            </div>
            <span>{{ $t("lang.abroadbadyimg.step3text") }}</span>
            </div>
          </div>
          </div>
          <img
            src="../assets/service_way_mob_img_2@3x.png"
            alt=""
            class="img-mob"
            @click="previewImg(require('../assets/service_way_mob_img_2@3x.png'))"
          />
        </div>
        <div class="title-box"><span class="financing-title">{{ $t("lang.Foreign.TITLE") }}</span></div>
        <!-- 境外交易所 -->
        <div class="part">
          <div class="abroad">
            <div class="abroad-img">
              <div class="opacity-box">
                <div class="box-child">
                   <el-tooltip class="item" effect="dark" placement="top">
                     <div slot="content">{{ $t("lang.Foreign.profit") }} <br/>{{ $t("lang.Foreign.market") }}<br/>{{ $t("lang.Foreign.other") }}</div>
                     <div class="item"></div>
                   </el-tooltip>
                   <el-tooltip class="item" effect="dark" placement="top">
                     <div slot="content">{{ $t("lang.Foreign.profit1") }}<br/>{{ $t("lang.Foreign.market1") }}<br/>{{ $t("lang.Foreign.other1") }}</div>
                     <div class="item"></div>
                   </el-tooltip>
                   <el-tooltip class="item" effect="dark" placement="top">
                     <div slot="content">{{ $t("lang.Foreign.profit2") }}<br/>{{ $t("lang.Foreign.market2") }}<br/>{{ $t("lang.Foreign.other2") }}</div>
                     <div class="item"></div>
                   </el-tooltip>
                </div>
                <div class="box-child">
                   <el-tooltip class="item" effect="dark" placement="top">
                     <div slot="content">{{ $t("lang.Foreign.profit3") }} <br/>{{ $t("lang.Foreign.market3") }}</div>
                          <div class="item"></div>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" placement="top">
                          <div slot="content">{{ $t("lang.Foreign.profit4") }}  <br/>{{ $t("lang.Foreign.market4") }}</div>
                          <div class="item"></div>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" placement="top">
                          <div slot="content">{{ $t("lang.Foreign.profit5") }} <br/>{{ $t("lang.Foreign.market5") }}<br/>{{ $t("lang.Foreign.other5") }}</div>
                          <div class="item"></div>
                        </el-tooltip>
                </div>
              </div>
            </div>
            <div class="abroad-img-right">
                   <el-tooltip class="item" effect="dark" placement="top">
                     <div slot="content">{{ $t("lang.Foreign.profit6") }} <br/>{{ $t("lang.Foreign.market6") }}</div>
                      <div class="box"></div>
                   </el-tooltip>
                   <el-tooltip class="item" effect="dark" placement="top">
                     <div slot="content">{{ $t("lang.Foreign.profit7") }}<br/>{{ $t("lang.Foreign.market7") }}</div>
                      <div class="box center"></div>
                   </el-tooltip>
                   <el-tooltip class="item" effect="dark" placement="top">
                     <div slot="content">{{ $t("lang.Foreign.profit8") }} <br/>{{ $t("lang.Foreign.market8") }}<br/>{{ $t("lang.Foreign.other8") }}</div>
                      <div class="box right"></div>
                   </el-tooltip>
            </div>
          </div>
          <img
            src="../assets/service_way_mob_img_3@3x.png"
            alt=""
            class="img-mob"
            @click="previewImg(require('../assets/service_way_mob_img_3@3x.png'))"
          />
        </div>
        <div class="title-box territory-title"><span class="financing-title">{{ $t("lang.domestic.TITLE") }}</span></div>
        <!-- 境内交易所 -->
        <div class="territory">
            <div class="box">
              <el-tooltip effect="dark"  placement="top">
                <div slot="content" style="max-width:347px;line-height:20px;" v-html='$t("lang.domestic.SHENGZHEN")'></div>
                <div class="box-tip"></div>
              </el-tooltip>
              <img
                  src="../assets/service_exchange_img_4a.png"
                  alt=""
                  class="box-img"
                />
            </div>
            <div class="box">
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="max-width:347px;line-height:20px;" v-html='$t("lang.domestic.SHANGHAI")'></div>
                <div class="box-tip"></div>
              </el-tooltip>
              <img
                  src="../assets/service_exchange_img_4b.png"
                  alt=""
                  class="box-img"
                />
            </div>
            <div class="box">
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="max-width:347px;line-height:20px;" v-html='$t("lang.domestic.BEIJIN")'></div>
                <div class="box-tip"></div>
              </el-tooltip>
              <img
                  src="../assets/service_exchange_img_4c.png"
                  alt=""
                  class="box-img"
                />
            </div>
          </div>
      </div>
      <!-- 知识产权证券化 -->
        <div class="part-knowledge">
          <span class="knowledge-title">{{ $t("lang.security.TITLE") }}</span>
            <p class="knowledge-text">{{ $t("lang.security.center") }}</p>
          <div class="content-footer">
            <div class="way"><p>{{ $t("lang.security.entity") }}</p><p class="way-case">{{ $t("lang.security.entitycenter") }}</p></div>
            <div class="way"><p>{{ $t("lang.security.number") }}</p><p class="way-case">{{ $t("lang.security.numbercenter") }}</p></div>
            <div class="way"><p>{{ $t("lang.security.intellectual") }}</p><p class="way-case">{{ $t("lang.security.intellectualcenter") }}</p></div>
            <div class="way"><p>{{ $t("lang.security.property") }}</p><p class="way-case">{{ $t("lang.security.propertycenter") }}</p></div>
          </div>
        </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Carousel from '@/components/Carousel/Carousel.vue'
import Copyright from '@/components/Copyright/Copyright.vue'

export default {
  name: 'Financing',
  components: {
    Header,
    Carousel,
    Copyright
  },
  data () {
    return {
      developimgurl: '',
      servicewayurl: ''
    }
  },
  // watch: {
  //   $route (to, from) {
  //     this.developimgurl = this.$t('lang.develop.img')
  //     this.servicewayurl = this.$t('lang.abroadbadyimg.img')
  //   }
  // },
  mounted () {
    this.$store.dispatch('setNavIndex', 1)
    this.developimgurl = this.$t('lang.develop.img')
    this.servicewayurl = this.$t('lang.abroadbadyimg.img')
  },
  methods: {
    previewImg (url) {
      // this.$hevueImgPreview({
      //   url,
      //   clickMaskCLose: 'open'
      // })
    }
  }
}
</script>
<style scoped lang="less">
.main {
  box-sizing: border-box;
  background: #efefef;
  max-width:1200px;
  width:1200px;
  margin: auto;
  // 标题
  .title-box {
    padding: 6vh 0 3vh 0;
    .financing-title {
      border-left: 3px solid #17CAD0;
      padding-left: 1vw;
      font-weight: bold;
      font-size:1.17rem;
    }
    }
    .territory-title {
      padding-bottom: 0;
      padding-top: 7vh;
    }
    // 模块最外层盒子
  .part {
    // padding: 3vh 5vw;
    padding: 30px 40px;
    background: white;
    .img-web {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
    .img-mob {
      display: none;
    }
    // 境外上市途径
    .abroad-way {
      display: flex;
      align-items: center;
      // 右侧步骤
      .step {
        display: flex;
        color: #019EFA ;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: space-around;
        font-weight: bold;
        padding-left: 50px;
        padding-left: 15px;
        .step-box {
          display: flex;
          align-items: center;
          .step-img {
            width: 121px;
            height: 121px;
            background: linear-gradient(180deg, #02CDFD 0%, #019EFA 100%);
            border-radius: 50%;
            position: relative;
            .steptext{
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 1;
              .steptext-item{
                width:55%;
                text-align: center;
              }
            }
          }
          .step-img::after {
            content:'';
            display: block;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: #fff;
            position: relative;
            top: 20px;
            left: 20px;
            line-height: 80px;
            text-align: center;
          }
          .step-img2::after {
            content:'';
          }
          .step-img1::after {
            content:'';
          }
          span {
            margin-left: 2vw;
            width: 24vw;
            padding-right: 10px;
          }
        }
      }
      // 左边图片
      .abroad-way-img {
        width: 40%;
        height:100%
      }
    }
    // 境外交易所
    .abroad {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .abroad-img {
        // width: 767px;
        // height: 382px;
        width: 680px;
        height: 339px;
        background-image: url('../assets/service_exchange_img_3a.png');
        background-position: center;
        background-size: 100% 100%;
        position: relative;
        .opacity-box {
        // width: 42vw;
        // height: 11vh;
        width: 590px;
        height: 48px;
        position: absolute;
        // bottom: 6vh;
        bottom: 55px;
        // left: -1vw;
        left:50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        .box-child {
          width: 49%;
          display: flex;
          justify-content: space-between;
          .item {
            width: 96px;
            height: 100%;
          }
        }
        }
      }
      .abroad-img-right {
        // width: 568px;
        // height: 259px;
        width: 440px;
        height: 229px;
        background-image: url('../assets/service_exchange_img_3b.png');
        background-size: 100% 100%;
        position: relative;
        .box {
          width: 75px !important;
          height:40px !important;
          position: absolute;
          bottom: 48px !important;
          left: 40px !important;
        }
        .center {
          left: 155px !important;
        }
        .right {
          left: 325px !important;
        }
      }
    }
  }
  // 境内较易所
  .territory {
    display: flex;
    .box {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .box-img {
       width: 347px;
      }
      .box-tip{
        width:255px;
        height: 230px;
        position:absolute;
        left: 50%;
        top: 48%;
        transform: translate(-50%,-50%);
        z-index: 999;
      }
    }
  }
}
  // 知识产权证券化
  .part-knowledge {
    padding: 3vh 5vw;
    height: 320px;
    background-image: url('../assets/BG.png');
    background-size: 100% 100%;
    .knowledge-title {
      color: white;
      font-weight: bold;
    }
       .knowledge-text {
         width: 35%;
         color: #999;
         font-size: 12px;
         line-height: 20px;
         margin-top: 2vh;
       }
       .content-footer {
         margin-top: 10vh;
         display: flex;
         .way {
           margin-right: 64px;
           border: 1px solid #fff;
           padding: 16px 0 16px 24px;
           color: white;
           min-width: 180px;
           max-width: 220px;
           height: 52px;
           .way-case {
             margin-top: 8px;
             font-size: 11px;
             color: #999;
           }
         }
       }
  }
.container{
  background: #efefef;
  .Header-item{
    position: absolute;
    z-index: 10;
    width: 100%;
  }
}
</style>
